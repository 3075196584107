import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'

class ImageAndText extends React.Component {
  render() {
    const isSquare = this.props.isSquare
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'horizontal',
        backgroundColor: colors.lightGrey,
        alignItems: 'center',
        ...themeStyles.elementMargin,
        flexWrap: 'wrap',
      },
      image: {
        display: 'flex',
        width: '100%',
        height: '100%',
        minHeight: isSquare ? '300px' : '235px',
        minWidth: '300px',
        [presets.Mobile]: {
          minHeight: isSquare ? '430px' : '385px',
          minWidth: isSquare ? '430px' : '370px',
        },
        [presets.Tablet]: {
          minHeight: isSquare ? '650px' : '380px',
          minWidth: isSquare ? '650px' : '520px',
        },
        alignItems: 'center',
        flex: 1,

        position: 'relative',
      },
      body: {
        display: 'flex',
        flex: 2,
        ...themeStyles.textPadding,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexBasis: isSquare ? '30%' : '50%',
      },
    }
    const image = (
      <div
        css={[
          styles.image,
          {
            backgroundImage: `url(${this.props.image})`,
            backgroundPosition: this.props.focus || 'center',
            backgroundSize: this.props.backgroundSize || 'cover',
            backgroundRepeat: 'no-repeat',
          },
        ]}
      />
    )
    const body = <div css={styles.body}>{this.props.children}</div>
    return (
      <div css={styles.container}>
        {this.props.reverse ? body : image}
        {this.props.reverse ? image : body}
      </div>
    )
  }
}

export default ImageAndText
