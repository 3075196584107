import React from 'react'

// App components
import CustomLink from '../components/CustomLink'

// App content
import bannerFace from '../assets/bannerface.jpg'

// App utils
import { themeStyles, presets } from '../utils/theme'

class Promo extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          position: 'relative',
          backgroundColor: 'rgba(1,1,1,0)',
        }}
      >
        <img
          src={bannerFace}
          css={{
            position: 'absolute',
            right: 0,
            top: 0,
            bottom: 0,
            maxHeight: '200px',
            zIndex: 1,
            margin: 0,
            display: 'none',
            [presets.Desktop]: { display: 'block' },
          }}
        />
        <h3
          css={{
            textAlign: 'center',
            ...themeStyles.textPadding,
            zIndex: 2,
            margin: 0,
          }}
        >
          A Confident Smile is Just a Phone Call Away
        </h3>
        <CustomLink
          href="tel:5072585400"
          css={{ zIndex: 2, textDecoration: 'none' }}
        >
          <h1 css={{ zIndex: 2, margin: 0, ...themeStyles.blueText }}>
            (507) 258-5400
          </h1>
        </CustomLink>
      </div>
    )
  }
}

export default Promo
